<style src="../css/head.css"></style>

<style src="../css/detail.css"></style>

<template>
  <div>
    <Store-Head-section></Store-Head-section>
    <div class="container">
      <div class="row clearfix featurette">
        <div class="col-md-7" v-if="skus.length > 0">
          <img
            :src="skus[col_index].images[0].path"
            class="img-fluid"
            alt="Responsive image"
            ref="img_head"
            v-if="skus[col_index].images.length > 0"
          />
          <div class="scrolling-wrapper-flexbox">
            <div
              class="card wh30"
              v-for="prd in skus[col_index].images"
              :key="prd.id"
            >
              <img
                :src="prd.path"
                class="img-fluid"
                @click="chageHeadImg(prd.path)"
              />
            </div>
          </div>
        </div>
        <div class="col-md-5 featurette" v-if="skus.length > 0">
          <dt>{{ prodName }}</dt>
          <dt class="text-muted my-1">{{ description }}</dt>
          <!--<dt v-if="lan != 'cn'" @click="showWorryFree()">-->
          <dt v-if="lan != 'cn'">
            <mark class="red_bg_tx_pink2 mx-1">{{
              $t("message.Bluedio_Worry_free")
            }}</mark>
          </dt>

          <dt class="font-weight-light" v-if="skus[col_index].saveratio != ''">
            <del>
              <span>{{ symble_money }}{{ (lan=='jp')?(skus[col_index].price*currency).toFixed(0):(skus[col_index].price*currency).toFixed(2) }}</span> </del
            >&nbsp;&nbsp;
          </dt>
          <dt v-if="skus[col_index].saveratio != ''">
            <div v-if="skus[col_index].fans == 1"><del>{{ symble_money }}{{ (lan=='jp')?(skus[col_index].discountprice*currency).toFixed(0):(skus[col_index].discountprice*currency).toFixed(2) }}</del>
            <mark class="red_bg_tx_pink mx-1">{{
              skus[col_index].saveratio
            }}</mark></div>
            <div v-else>{{ symble_money }}{{ (lan=='jp')?(skus[col_index].discountprice*currency).toFixed(0):(skus[col_index].discountprice*currency).toFixed(2) }}
            <mark class="red_bg_tx_pink mx-1">{{
              skus[col_index].saveratio
            }}</mark></div>
          </dt>
          <dt v-if="skus[col_index].saveratio == ''">
            <del v-if="skus[col_index].fans == 1">{{ symble_money }}{{ (lan=='jp')?(skus[col_index].price*currency).toFixed(0):(skus[col_index].price*currency).toFixed(2) }}</del>
            <div v-else>{{ symble_money }}{{ (lan=='jp')?(skus[col_index].price*currency).toFixed(0):(skus[col_index].price*currency).toFixed(2) }}</div>
          </dt>
           <dt v-if="skus[col_index].fans == 1">
            {{ symble_money }}{{ (lan=='jp')?(skus[col_index].fansprice*currency).toFixed(0):(skus[col_index].fansprice*currency).toFixed(2) }}
            <mark class="red_bg_tx_pink mx-1">{{ $t("message.Fans_discount") }}&nbsp;10% ↓ </mark>
          </dt>
    
          <dt id="productCountdownTips" v-if="skus[col_index].countdown == 1" class="font-weight-light">
            <count-down
             id="productCountdown"
              :startTime="starttime"
              :endTime="skus[col_index].countdowntime"
              :tipText="'Discount countdown'"
              :tipTextEnd="$t('message.Discount_ends_in')"
              :endText="'Discount has ended'"
              :dayTxt="$t('message.Discount_unit_day')"
              :hourTxt="':'"
              :minutesTxt="':'"
              :secondsTxt="''"
            ></count-down>
          </dt>
          <div class="mb-3">
            <button
              class="btn mt-3"
              v-for="(sku_col, idx) in skus"
              :key="sku_col.id"
              :class="{
                'btn-outline-danger': idx == col_index,
                'btn-outline-dark': idx != col_index,
                'ml-3': idx != 0,
              }"
              @click="selCol(idx)"
            >
              {{ sku_col.sku }}
            </button> 
          </div>

           <div v-if="lan != 'cn'" class="mb-3">
            <dt class="font-weight-light"><i style="background:url(../assets/gou.png) no-repeat center;"></i><img src="../assets/gou.png" height="32" width="32"> &nbsp;<a style="text-decoration:underline;color:red;" href="/game/dreamtime">Buy the product and send the game</a></dt>
          </div>

          <div v-if="lan != 'cn'" class="mb-3">
            <dt v-if="skus[col_index].regionlimit == 0"><i class="fas fa-truck"></i> &nbsp;{{ $t("message.Free_ExpressDelivery") }}&nbsp;<i id="shipfrom" :class="'flag-icon flag-icon-'+skus[col_index].shipfrom"></i><b-tooltip target="shipfrom" :title="skus[col_index].langg"></b-tooltip></dt>
            <dt v-else><i class="fas fa-truck"></i> &nbsp;{{ $t("message.Region_limit") }}</dt>
          </div>

          <dt class="font-weight-light">
            {{ $t("message.STOCK") }}:{{ skus[col_index].stock }}
          </dt>
          <div class="container row mt-1">
            <span class="my-2 mr-1">{{ $t("message.QUANTITY") }}:</span>
            <div>
              <span @click="minusCart()" class="btn btn-outline-dark">-</span>
              <span class="text-center p-3" v-if="skus[col_index].stock > 0">{{
                quant
              }}</span>
              <span class="text-center p-3" v-if="skus[col_index].stock == 0">{{
                0
              }}</span>
              <span @click="plusCart()" class="btn btn-outline-dark">+</span>
            </div>
          </div>

          <div
      class="fixed-bottom text-center bg-white p-3 justify-content-center align-items-center" v-if="ismob">
      <b-list-group
        horizontal
        class="text-center justify-content-center align-items-center"
      >
        <b-list-group-item
          href="#"
          variant="success"
          :disabled="skus[col_index].regionlimit == 1"
          @click="
            toOrdPre(skus[col_index].id, quant, skus[col_index].customize, '')
          "
          ><i class="fas fa-credit-card"></i>
          {{ $t("message.Buy_now") }}</b-list-group-item
        >
        <b-list-group-item
          href="#"
          variant="primary"
          :disabled="skus[col_index].regionlimit == 1"
          @click="
            add_to_cart(
              skus[col_index].id,
              quant,
              skus[col_index].customize,
              ''
            )
          "
        >
          <i class="fas fa-shopping-cart"></i>
          {{ $t("message.ADD_TO_CART") }}
        </b-list-group-item>
      </b-list-group>
    </div>
          <div v-else>
            <b-button
              size="lg"
              variant="success outline-dark mt-3 mx-1"
              :disabled="skus[col_index].regionlimit == 1"
              @click="
                toOrdPre(skus[col_index].id, quant, skus[col_index].customize, '')
              "
              ><i class="fas fa-credit-card"></i>
              {{ $t("message.Buy_now") }}</b-button
            >
            <b-button
              size="lg"
              variant="primary outline-dark font-weight-light mt-3"
              :disabled="skus[col_index].regionlimit == 1"
              @click="
                add_to_cart(
                  skus[col_index].id,
                  quant,
                  skus[col_index].customize,
                  ''
                )
              "
              ><i class="fas fa-shopping-cart"></i>
              {{ $t("message.ADD_TO_CART") }}</b-button>
          </div>
        </div>
      </div>
      <!-- <hr />
      <p class="text-center">
        ------
        <strong>{{ $t("message.Product_Description") }}</strong
        >------
      </p> -->
      <br>
      <div class="divider">
          <span><strong>{{ $t("message.Product_Description") }}</strong
        ></span>
      </div>
     <br><br>
      <div class="container" v-for="prd_des in images_des" :key="prd_des.id">
      <div class="mt-3"  v-if="ismob">
          <b-card no-body class="overflow-hidden">
            <b-row no-gutters>
              <b-col md="12">
                <b-card-img-lazy
                  :src="prd_des.path"
                  :alt="prd_des.imageInfo.alt"
                  class="rounded-0"
                ></b-card-img-lazy>
              </b-col>
              <b-col md="12">
                <b-card-body title>
                  <b-card-text>
                    <div v-html="prd_des.imageInfo.title"></div>
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </div>
        <div class="mt-3" v-else-if="prd_des.imageInfo.type == 1">
          <b-card no-body class="overflow-hidden">
            <b-row no-gutters>
              <b-col md="6">
                <b-card-img-lazy
                  :src="prd_des.path"
                  :alt="prd_des.imageInfo.alt"
                  class="rounded-0"
                ></b-card-img-lazy>
              </b-col>
              <b-col md="6">
                <b-card-body title class="card-body-sytle">
                  <b-card-text class="card-text_item">
                    <div
                      v-html="prd_des.imageInfo.title"
                      class="card-text-div"
                    ></div>
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </div>

        <div class="mt-3" v-else-if="prd_des.imageInfo.type == 2">
          <b-card no-body class="overflow-hidden">
            <b-row no-gutters>
              <b-col md="6">
                <b-card-body title class="card-body-sytle">
                  <b-card-text class="card-text_item">
                    <div
                      v-html="prd_des.imageInfo.title"
                      class="card-text-div"
                    ></div>
                  </b-card-text>
                </b-card-body>
              </b-col>
              <b-col md="6">
                <b-card-img-lazy
                  :src="prd_des.path"
                  :alt="prd_des.imageInfo.alt"
                  class="rounded-0"
                ></b-card-img-lazy>
              </b-col>
            </b-row>
          </b-card>
        </div>

        <div class="mt-3" v-else-if="prd_des.imageInfo.type == 3">
          <b-card no-body class="overflow-hidden">
            <b-row no-gutters>
              <b-col md="12">
                <b-card-img-lazy
                  :src="prd_des.path"
                  :alt="prd_des.imageInfo.alt"
                  class="rounded-0"
                ></b-card-img-lazy>
              </b-col>
              <b-col md="12">
                <b-card-body title>
                  <b-card-text>
                    <div v-html="prd_des.imageInfo.title"></div>
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </div>

        <div class="mt-3" v-else>
          <b-card
            title
            :img-src="prd_des.path"
            :img-alt="prd_des.imageInfo.alt"
            img-top
          >
            <b-card-text
              style="width:0px;height:0px;overflow:hidden;"
            ></b-card-text>
          </b-card>
        </div>
      </div>
    </div>
    <Foot-section></Foot-section>
    <b-modal
      id="modal-custom"
      ref="modal-custom"
      :title="$t('message.Customize_your_product')"
      hide-footer
    >
      <div>
        <b-form-group label="Individual radios">
          <b-form-radio v-model="selected" name="some-radios" value="1">{{
            $t("message.Customize")
          }}</b-form-radio>
          <b-form-radio v-model="selected" name="some-radios" value="0">{{
            $t("message.None")
          }}</b-form-radio>
        </b-form-group>

        <div class="mt-3 mb-3">
          <button
            class="btn"
            v-for="(sku_col, idx) in skus"
            :key="sku_col.id"
            :class="{
              'btn-outline-danger': idx == col_index,
              'btn-outline-dark': idx != col_index,
              'ml-3': idx != 0,
            }"
            @click="selCol(idx)"
          >
            {{ sku_col.sku }}
          </button>
        </div>
        <div v-if="selected == 1">
          <label for="input-live">{{ $t("message.Customize_message") }}</label>

          <b-form-input
            id="input-live"
            v-model="text"
            :state="nameState"
            aria-describedby="input-live-help input-live-feedback"
            :placeholder="$t('message.Customize_message')"
            trim
          ></b-form-input>

          <b-form-invalid-feedback id="input-live-feedback">{{
            $t("message.You_need_fill_all_input")
          }}</b-form-invalid-feedback>
        </div>
        <b-img-lazy
          thumbnail
          fluid
          :src="skus[col_index].customizeImage"
          alt="Customize Product"
          class="mt-2"
          v-if="skus.length > 0"
        ></b-img-lazy>
        <div>
          <b-button variant="outline-primary" @click="hideModal()">{{
            $t("message.Cancle")
          }}</b-button>
          <b-button
            variant="success"
            class="ml-1 my-1"
            @click="
              add_to_cart_custom(skus[col_index].id, quant, selected, text)
            "
            >{{ $t("message.Sure") }}</b-button
          >
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-worry"
      ref="modal-worry"
      :title="$t('message.Bluedio_Worry_Title')"
      hide-footer
    >
      <div>
        <img
          src="../assets/shopping.jpg"
          class="mt-2 img-thumbnail img-fluid"
          alt="Bluedio"
        />
        <!-- <b-img thumbnail fluid src="../assets/customize_cn.jpg" alt="Bluedio" class="mt-2"></b-img> -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import StoreHeadSection from "./StoreHeadSection.vue";
import FootSection from "./FootSection.vue";
import CountDown from "vue2-countdown";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Product",
  components: {
    StoreHeadSection,
    FootSection,
    CountDown,
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "keywords",
          content: this.prodName,
        },
      ],
    };
  },
  data() {
    return {
      skus: [],
      images_des: [],
      prodName: "",
      selected: 1,
      description: "",
      sku: "",
      col_index: 0,
      is_now: 0,
      text: "",
      quant: 1,
      currency: 1.0,
      regionlimit: 0,
      starttime: new Date().getTime(),
      countdowntime: 1594397350520
    };
  },
  created() {
    let p1 = this.$route.path.split("/")[1];
    if(p1 == 'product'){
      this.toEnProduct();
    }
    // let language = this.$route.params.language;
    // if(this.$store.state.lan_array.indexOf(language) == -1){
    //   this.toHome();
    // }
    
    
    let track = this.$route.query.referer;
    if (typeof(track) != "undefined" && track!=''){
      let dateTime = new Date();
      dateTime=dateTime.setDate(dateTime.getDate()+1);
      dateTime=new Date(dateTime);
      let referer = {"refererid":track, "date":dateTime.getTime()}
      // localStorage.setItem("site_referer", JSON.stringify(referer));
      sessionStorage.setItem("site_referer", JSON.stringify(referer));
    }else{
      // let referer = localStorage.getItem("site_referer");
      let referer = sessionStorage.getItem("site_referer");
          track = '';
          if (referer!=null){
            trackid = JSON.parse(referer).refererid;
                // let tdate = new Date(JSON.parse(referer).date);
                // if(new Date().getTime() < tdate.getTime()){
                //     track = JSON.parse(referer).refererid;
                // }else{
                //   sessionStorage.removeItem("site_referer");
                // }
          }
    }
    this.getPrdItem(this.$route.params.proid,track);
  },
  computed: {
    ...mapGetters(["lan","lang", "lan_array", "access_token", "symble_money","ismob"]),
    nameState() {
      return this.text != "" ? true : false;
    }
  },

  watch: {
    $route() {
      // let referer = localStorage.getItem("site_referer");
      let referer = sessionStorage.getItem("site_referer");
        let trackid = '';
         if (referer!=null){
           trackid = JSON.parse(referer).refererid;
          // let tdate = new Date(JSON.parse(referer).date);
          // if(new Date().getTime() < tdate.getTime()){
          //     trackid = JSON.parse(referer).refererid;
          // }else{
          //   // localStorage.removeItem("site_referer");
          //   sessionStorage.removeItem("site_referer");
          // }
         }
      this.getPrdItem(this.$route.params.proid,trackid);
    },
    lan() {
      this.$router.push({
        name: "enhome",
      });
    },
  },
  methods: {
    ...mapActions({
      getPrdItem(dispatch, proId,trackid) {
        dispatch("getPrdItem", {
          proId,
          trackid
        }).then((data) => {
          if (data.result) {
            this.skus = data.item.skus;
            this.images_des = data.item.images;
            this.sku = data.item.skus[0].sku;
            this.prodName = data.item.productname;
            this.description = data.item.description;
            this.regionlimit = data.item.regionlimit;
            this.productLocation = data.item.location;
            this.currency = data.item.currency;
            if(this.lan!='' && this.lan != data.item.location){
              this.toHome();
            }
          }
        });
      },

      getShopCarLs(dispatch) {
        dispatch("getShopCarLs", {}).then((data) => {
          this.select_cart_ls = data.result;
          if (data.result) {
            this.$store.state.items_cart = data.items;
          }
        });
      },
      toOrdPre(dispatch, skuid, count, customized, customizedesc) {
        this.is_now = 1;
        if (count > this.skus[this.col_index].stock) {
          this.$bvToast.toast(this.$t("message.Insufficient_quantity"), {
            title: ``,
            toaster: "b-toaster-bottom-center",
            solid: true,
            variant: "danger",
            appendToast: false,
          });
          return;
        }

        if (this.access_token == "" && this.lan == "cn") {
          this.$bvToast.toast(this.$t("message.Please_Log_In"), {
            title: ``,
            toaster: "b-toaster-bottom-center",
            solid: true,
            variant: "danger",
            appendToast: false,
          });
          return;
        }

        if (customized) {
          this.showModal();
          return;
        }
        dispatch("add_to_cart", {
          skuid,
          count,
          customized,
          customizedesc,
        }).then((data) => {
          if (data.result) {
            this.$router.push({
              name: "enOrderLsPre",
            });
          } else {
            if (data.status == "logout") {
              this.showToast(this.$t("message.You_logout"));
              this.logout();
            }
          }
        });
      },
      add_to_cart(dispatch, skuid, count, customized, customizedesc) {
        this.is_now = 0;
        if (count > this.skus[this.col_index].stock) {
          this.$bvToast.toast(this.$t("message.Insufficient_quantity"), {
            title: ``,
            toaster: "b-toaster-bottom-center",
            solid: true,
            variant: "danger",
            appendToast: false,
          });
          return;
        }

        if (this.access_token == "" && this.lan == "cn") {
          this.$bvToast.toast(this.$t("message.Please_Log_In"), {
            title: ``,
            toaster: "b-toaster-bottom-center",
            solid: true,
            variant: "danger",
            appendToast: false,
          });
          return;
        }

        if (customized) {
          this.showModal();
          return;
        }

        dispatch("add_to_cart", {
          skuid,
          count,
          customized,
          customizedesc,
        }).then((data) => {
          if (data.result) {
            this.getShopCarLs();
            this.showToast(this.$t("message.Add_cart"));
          } else {
            if (data.status == "logout") {
              this.showToast(this.$t("message.You_logout"));
              this.logout();
            }
          }
        });
      },
      add_to_cart_custom(dispatch, skuid, count, customized, texts) {
        if (count > this.skus[this.col_index].stock) {
          this.$bvToast.toast(this.$t("message.Insufficient_quantity"), {
            title: ``,
            toaster: "b-toaster-bottom-center",
            solid: true,
            variant: "danger",
            appendToast: false,
          });
          return;
        }

        if (this.access_token == "" && this.lan == "cn") {
          this.$bvToast.toast(this.$t("message.Please_Log_In"), {
            title: ``,
            toaster: "b-toaster-bottom-center",
            solid: true,
            variant: "danger",
            appendToast: false,
          });
          return;
        }
        if (this.selected == 1 && this.text == "") {
          return;
        }

        let customizedesc = texts;
        if (this.selected == 0) {
          customizedesc = "";
        }
        dispatch("add_to_cart", {
          skuid,
          count,
          customized,
          customizedesc,
        }).then((data) => {
          if (data.result) {
            if (this.is_now == 0) {
              this.getShopCarLs();
              this.hideModal();
            } else {
              this.$router.push({
                name: "enOrderLsPre",
              });
            }
          } else {
            if (data.status == "logout") {
              this.showToast(this.$t("message.You_logout"));
              this.logout();
              this.hideModal();
            }
          }
        });
      },
      //展示
      showBigPic(filepath) {
        //将文件路径传给img大图
        document.getElementById("bigPic").src = filepath;
        //获取大图div是否存在
        var div = document.getElementById("bigPicView");
        if (!div) {
          return;
        }
        //如果存在则展示
        document.getElementById("bigPicView").style.display = "block";
        //获取鼠标坐标
        var intX = window.event.clientX;
        var intY = window.event.clientY;
        //设置大图左上角起点位置
        div.style.left = intX + 10 + "px";
        div.style.top = intY + 10 + "px";
      },

      //隐藏
      closeimg() {
        document.getElementById("bigPicView").style.display = "none";
      },
    }),
    selCol(idx) {
      this.col_index = idx;
    },
    chageHeadImg(path) {
      this.$refs["img_head"].src = path;
    },
    plusCart() {
      var count = this.quant;
      var stock = this.skus[this.col_index].stock;
      if (count < stock) this.quant = count + 1;
    },
    minusCart() {
      var count = this.quant;
      // console.log("============count=====" + count);
      if (count > 1) this.quant = count - 1;
    },
    hideModal() {
      this.$refs["modal-custom"].hide();
    },
    showModal() {
      this.$refs["modal-custom"].show();
    },
    showWorryFree() {
      this.$refs["modal-worry"].show();
    },
    showToast(msg) {
      this.$bvToast.toast(msg, {
        title: ``,
        toaster: "b-toaster-bottom-center",
        solid: true,
        variant: "danger",
        appendToast: false,
      });
    },
    //     pollData() {
    // // (prd) in skus[col_index].images

    //       this.polling = setInterval(() => {
    //         this.$refs["img_head"].src = 'path';
    //       }, 25000);
    //     },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("store");
      localStorage.removeItem("usrname");
      localStorage.removeItem("usrid");
      this.$store.state.usr = "";
      this.$store.state.usrid = "";
      this.$store.state.access_token = "";
      this.$store.state.items_cart = [];
      this.toHome();
    },
    toHome() {
      let nameHome = this.lan + "home";
      this.$router.replace({
        name: nameHome
      });
    },
    toEnProduct() {
      this.$router.replace({
        name: 'enProduct'
      });
    },
  },
};
</script>
<style>
 #productCountdownTips > p{padding:0px; margin:0px;}
 #productCountdownTips > div{padding:0px; margin:0px;}
 #productCountdownTips > div > p{padding:0px; margin:0px;}
 #productCountdown > p{padding:0px; margin:0px;}
 .card {
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.0rem;
}

.divider {
    height: 1px;
    margin-top: 40px;
    text-align:center;
    border-top: 1px solid #e2e2e2;
}
.divider span{
    position: relative;
    top: -20px;
    background: #fff;
    font-size: 26px;
    font-weight: bold;
    padding: 0 12px;
}
</style>
